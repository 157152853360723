import $ from 'jquery'

if ( $( '.slider-container' ).length ) {

  let i;
  const section = document.getElementsByClassName('slider-container')

  for(i=0; i<section.length; i++) {
    let cardIndex = 0

    const leftArrow = section[i].querySelector('.slider-arrow.left')
    const rightArrow = section[i].querySelector('.slider-arrow.right')

    const card = section[i].getElementsByClassName('project-card')


    function toggleHide( x ) {
      $( x ).siblings('.project-card').addClass( 'hide' )
      $( x ).removeClass( 'hide' )
      $( x ).removeClass( 'next' )
      $( x ).next().addClass( 'next' )
    }

    $( leftArrow ).on( 'click', function(){
      if(cardIndex > 0) {
        cardIndex -= 1
      } else {
        cardIndex = (card.length - 1)
      }
      toggleHide( card[ cardIndex ] )
    })

    $( rightArrow ).on( 'click', function(){

      if(cardIndex > (card.length - 2)) {
        cardIndex = 0
      } else {
        cardIndex += 1 
      }
      toggleHide( card[ cardIndex ] )
    })
  }
}
