// this is for any GSAP related anims
import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';

let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger, ScrollToPlugin );

if ( $( '.parallax-container' ).length ) {
  ScrollTriggerInit
}
if ( $( '.hero-parallax' ).length ) {
  ScrollTriggerInit
}
if( $( '.parallax-container' ).length ) {

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.parallax-container',
      start: 'top bottom',
      end: 'bottom top',
      scrub: 2
    }
  });

  tl
    .fromTo('.parallax-1', { y: 200 }, { y: 0 }, 0)
    .fromTo('.parallax-title', { x: -50 }, { x: 50 }, 0.1)
    .fromTo('.parallax-2', { y: 200 }, { y: -20 }, 0.1 )
    .fromTo('.parallax-3', { y: 200 }, { y: -50 }, 0.1 )
    .fromTo('.parallax-4', { y: 220 }, { y: -80 }, 0.2 );
}

if( $( '.hero-parallax' ).length ) {
  ScrollTrigger.matchMedia({
    "(min-width: 960px)": function() {
      const heroTl = gsap.timeline({
        scrollTrigger: {
          trigger: '.hero-parallax',
          start: 'top top',
          end: 'bottom top',
          scrub: 2, 
        }
      });
      
      heroTl
        .fromTo('.parallax-hero-img', { y: -100 }, { y: -200 }, 0)
        .fromTo('.description-container', { y: 200 }, { y: -100 }, 0)
        .fromTo('.parallax-hero-title', { x: 0 }, { x: -100 }, 0.1)
    },

    "(max-width: 959px)": function() {
      const heroTl = gsap.timeline({
        scrollTrigger: {
          trigger: '.hero-parallax',
          start: 'top top',
          end: 'bottom top',
          scrub: 2, 
        }
      });
      
      heroTl
        .fromTo('.parallax-hero-img', { y: -100 }, { y: -200 }, 0)
        .fromTo('.description-container', { y: 50 }, { y: -100 }, 0)
        .fromTo('.parallax-hero-title', { x: 25 }, { x: -50 }, 0)
    }
  })
  
}

if( $( '.sideways-container' ).length ) {
  ScrollTrigger.matchMedia({
    // desktop
    "(min-width: 960px)": function() {
      // ScrollTrigger (this automatically gets killed when the breakpoint no longer matches...
      var container = $('.sideways-container');
      var boxes = $('.sideways-news-container');
      let sidewaysTL = gsap.timeline();

      sidewaysTL.to( boxes, {
        x: () => { return -(container[0].scrollWidth - document.documentElement.clientWidth) + "px" },
        ease: 'none',
        scrollTrigger: {
          trigger: boxes,
          start: () => 'center center',
          end: () => { return '+=' + container[0].scrollWidth },
          id: 'sideScroll',
          scrub: true,
          pin: container,
          pinSpacing: true,
          anticipatePin: 1
        }
      });

      function killTL() {
        sidewaysTL.kill(); 
      };
      // $( window ).on( 'load', function() {    
      //   var hash = window.location.hash;
      //   if( $( hash ).length ) {
      //     hashScroll()
      //   }
      // });

      $( window ).on('hashchange', function() {
        hashScroll(); 
      });

      function hashScroll() {
        var sideScroll = ScrollTrigger.getById( 'sideScroll' )
        var hashID = window.location.hash.replace( '#', '' )
        var hashPosition = $('#' + hashID ).position().top;
        var pinSpacerPosition = $( '.pin-spacer' ).position().top;

        if ( hashID == 'projects') {
          sideScroll.scroll(pinSpacerPosition); 
        } else {
          sideScroll.scroll(hashPosition);
        }
      }
      //Return a function that'll get called when the breakpoint no longer matches so we can kill() the animation (or whatever)
      return killTL();   
    }
  });
}

if ( $( '[data-slideup]' ).length ) {
  $( window ).on( 'load', function() {
    ScrollTriggerInit;
    let slideUp = document.querySelectorAll( '[data-slideup]' );

    slideUp.forEach((elem, i) => {
      ScrollTrigger.create({
        trigger: elem,
        start:"top-=100% bottom",
        end:"bottom-=100% top",
        onEnter: () => $(elem).addClass('slide-up'),
        onLeaveBack: () => $(elem).removeClass('slide-up'),
        onEnterBack: () => $(elem).addClass('slide-up'),
      });
    });
  });
}
if ( $( '[data-fadein]' ).length ) {
  $( window ).on( 'load', function() {
    ScrollTriggerInit;
    let fadeIn = document.querySelectorAll( '[data-fadein]' );

    fadeIn.forEach((elem, i) => {
      ScrollTrigger.create({
        trigger: elem,
        start:"top+=20px bottom", 
        onEnter: () => $(elem).addClass('fade-in'),
        onLeaveBack: () => $(elem).removeClass('fade-in'),
        onEnterBack: () => $(elem).addClass('fade-in'),
      });
    });
  });
}