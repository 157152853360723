import $ from 'jquery';

$( window ).on( 'load', () => {

  $( '#toggle-menu' ).on( 'click', () => {
      $( '.nav' ).toggleClass( 'open' );
  });

  $( '.scroll-top' ).on( 'click', ( e ) => {
    e.preventDefault();
    $( 'html, body, .modal' ).scrollTop( 0 );
  });
  
  // check on load if the nav is checked uncheck it for when a user hits the back button
  if ( $( '#toggle-menu' ).is( ':checked' ) ) {
    $( '#toggle-menu' ).prop( 'checked', false );
  }

  // addding no scroll when the menu is open
  const checkbox = document.getElementById( 'toggle-menu' );

  // Hide and remove
  checkbox.addEventListener( 'change', () => {
    $( 'body' ).toggleClass( 'no-scroll' );
  });
});
   
var position = $( window ).scrollTop(); 
var headerHeight = $( '.page-header' ).height();
var navHeight = $( '.nav' ).height();
// should start at 0

$( window ).on( 'scroll', () => {
  var scroll = $( window ).scrollTop();
  //if the user scrolls past the navigation height, adds position fixed 
  // and translates the menu -100%;
  if ( scroll >= navHeight ) { 
    $( '.nav' ).addClass( 'nav-fix' );
  } else {
    $( '.nav' ).removeClass( 'nav-fix' );
  }
  // when the user scrolls page the hero, adds a nav-animate class so 
  // the fixed nav can now animate
  if( scroll > position || scroll <= navHeight) { 
    $( '.nav' ).removeClass( 'nav-animate' );
  } else {
    $( '.nav' ).addClass( 'nav-animate' );
  }
  position = scroll;
  // when the user makes an upwards scroll the nav translates to 0% 
  // if it has the nav-animate class also
  if ( scroll >= headerHeight ) {
    $( '.nav' ).addClass( 'nav-show' );
  } else {
    $( '.nav' ).removeClass( 'nav-show' );
  }
});

$( window ).on( 'load', () => {
  // to scroll to the window hash if navigating from the news page
  var hash = window.location.hash;

  // $( '.menu-item a' ).on( 'click', function() {  
  //   hash = $(this).attr('href');
  // });

  if( $(hash).length ) {
    $('html, body').animate({scrollTop: $(hash).offset().top}, 300);
  }
});